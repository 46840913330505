<template>
  <section>
    <div class="wrapper">
      <img
        src="../../assets/img/not-verified.png"
        alt=""
        class="refusal-image"
      />

      <h3>{{ localize('refusal')['title'] }}</h3>

      <!--
            <p>{{ localize('refusal')['text'] }}</p>
      
            <div class="cause">
              <div class="icon">
                <span v-if="causeType === 'overdue'">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.2192 12.0002V17.2192M21.2192 17.2192H16.0002M21.2192 17.2192L13.4482 9.44825L9.44825 13.4482L2.78125 6.78125"
                    stroke="#6610F5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
      
                <span v-else>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.33301 19.841V15.334C5.33301 14.229 6.22801 13.334 7.33301 13.334H16.666C17.771 13.334 18.666 14.229 18.666 15.334V19.841M14.9634 7.123C14.9634 8.75942 13.6368 10.086 12.0004 10.086C10.364 10.086 9.03738 8.75942 9.03738 7.123C9.03738 5.48658 10.364 4.16 12.0004 4.16C13.6368 4.16 14.9634 5.48658 14.9634 7.123Z"
                    stroke="#6610F5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </div>
      
              <div>{{ localize('refusal')['causes'][causeType] }}</div>
            </div>
      -->

      <main-button
        class="checkStatusButton not-cause"
        :label="localize('button')['goHome']"
        @click="makeRoute"
      />
    </div>
  </section>
</template>

<script>
import MainButton from '../../components/buttons/MainButton';
import localize from '../../filters/localize.filter';

export default {
  name: 'Refusal',
  data() {
    return { causeType: 'overdue' };
  },
  components: { MainButton },
  created() {
    this.$store.dispatch('buyers/userStatus');
  },
  methods: {
    localize,
    makeRoute() {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  background: $grey;
  height: 100vh;
  padding-top: 80px;

  .wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 575px;
    margin: 0 auto;

    .refusal-image {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }

    h3 {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      margin-top: 24px;
      color: $black;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: $black;
      margin-top: 24px;
    }

    .cause {
      width: 384px;
      background: $white;
      border-radius: 8px;
      padding: 16px 12px;
      margin: 24px auto;
      display: flex;
      align-items: center;
      text-align: left;

      .icon {
        position: relative;
        background-color: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        width: 40px;
        height: 40px;
        margin-right: 12px;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .checkStatusButton {
      width: 384px;
      margin: 0 auto;

      &.not-cause {
        margin-top: 64px;
      }
    }
  }
}

@media (max-width: 575px) {
  section {
    .wrapper {
      max-width: 320px;

      .refusal-image {
        width: 250px;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 15px;
        line-height: 24px;
      }

      .cause {
        width: 288px;
        padding: 8px;

        .icon {
          margin-right: 12px;
        }
      }

      .checkStatusButton {
        width: 100%;
      }
    }
  }
}
</style>
